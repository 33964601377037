import React from "react"
import styled from "styled-components"

import HeroWebsite from "../../Elements/Images/HeroWebsite"
import HeroBranding from "../../Elements/Images/HeroBranding"
import HeroMarketing from "../../Elements/Images/HeroMarketing"
import { headlineOne, headlineTwo } from "../../Utilities"

const HeroImageSection = styled.section`
  position: relative;

  .hero-content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    max-width: 75rem;
    min-height: 40rem;
    margin: 0 auto;
    z-index: 5;

    @media (min-width: 1025px) {
      min-height: 50rem;
    }

    @media screen and (-ms-high-contrast: active),
      screen and (-ms-high-contrast: none) {
      margin: 0;
    }

    &__title {
      width: 100%;
      padding-bottom: 5rem;
      text-align: center;

      h1 {
        ${headlineOne};
        color: #fff;
        text-transform: uppercase;
      }
    }

    &__quote {
      width: 100%;
      text-align: center;

      p {
        ${headlineTwo};
        margin: 0;
        color: #fff;
      }
    }
  }

  .hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.5;
    z-index: 1;
  }
`

const HeroImage = props => {
  const { title, subTitle, bgImg } = props
  let HeroImg = false
  if (bgImg === "website") {
    HeroImg = HeroWebsite
  } else if (bgImg === "branding") {
    HeroImg = HeroBranding
  } else if (bgImg === "marketing") {
    HeroImg = HeroMarketing
  }

  return (
    <>
      {HeroImg && (
        <HeroImageSection>
          <HeroImg>
            <div className="hero-content">
              <div className="hero-content__title">
                <h1>{title}</h1>
              </div>
              <div className="hero-content__quote">
                <p>{subTitle}</p>
              </div>
            </div>
            <div className="hero-overlay" />
          </HeroImg>
        </HeroImageSection>
      )}
    </>
  )
}

export default HeroImage

import React from "react"
import { Link } from "gatsby"
import BgImage from "gatsby-background-image"
import styled from "styled-components"
import { headlineThree, fontSizer } from "../../../Utilities"

const CaseStudyCardStyled = styled.div`
  position: relative;
  min-height: 40rem;
  overflow: hidden;
  transition: all 0.4s ease;

  @media (min-width: 768px) {
    min-height: 50rem;
  }

  @media (min-width: 1025px) {
    min-height: 60rem;
  }

  .case-content-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(53, 197, 103, 0.75);
    text-align: center;
    z-index: 100;
    transition: all 0.4s ease;
    transform: translate(-100%, -100%);

    h2,
    p {
      ${headlineThree};
      ${fontSizer(2, 3, 76.8, 110, 2.2)}
      color: #fff;
      text-transform: uppercase;

      span {
        display: block;
      }
    }
  }

  &:hover {
    .case-content-container {
      transform: translate(0%, 0%);
      opacity: 1;
    }
  }
`

const CaseStudyBG = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .case-bg-img-container {
    width: 100%;
    height: 100%;
    padding: 0;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`

const CaseStudyCard = ({ study, position }) => {
  return (
    <CaseStudyCardStyled>
      <Link to={`/case-studies/${study.slug}`}>
        <div className="case-content-container">
          <div>
            <p>Case Study #{position}</p>
            <h2>
              {study.title} <span>{study.acf._swb_cs_title}</span>
            </h2>
          </div>
        </div>

        <CaseStudyBG>
          <BgImage
            className="case-bg-img-container"
            tag="div"
            fluid={
              study.acf._swb_cs_featured_img.localFile.childImageSharp.fluid
            }
          />
        </CaseStudyBG>
      </Link>
    </CaseStudyCardStyled>
  )
}

export default CaseStudyCard

import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/Seo/seo"
import HeroImage from "../components/Shared/HeroImage"
import CaseStudyCard from "../components/PageParts/CaseStudies/CaseStudyCard"

const CaseStudies = props => {
  const { caseStuides, metaImage } = props.data
  return (
    <Layout location={props.location.pathname}>
      <SEO
        title="Case Stuides - This is our work in action. Switchback Creative."
        description="We are here to guide you through the process, finding the best customers for your business. It’s on the field in the game and out there in the wild."
        location={props.location.pathname}
        pageImg={metaImage.publicURL}
      />
      <HeroImage
        bgImg="website"
        title="Case Studies"
        subTitle="This is our work in action. It’s on the field in the game and out there in the wild."
      />
      <div>
        {caseStuides.edges.map((study, index) => {
          return (
            <CaseStudyCard
              key={study.node.wordpress_id}
              position={index + 1}
              study={study.node}
            />
          )
        })}
      </div>
    </Layout>
  )
}

export const caseStuidesQuery = graphql`
  {
    caseStuides: allWordpressWpCaseStudy(
      sort: { fields: [date], order: [DESC] }
    ) {
      edges {
        node {
          wordpress_id
          title
          slug
          acf {
            _swb_cs_title
            _swb_cs_featured_img {
              alt_text
              localFile {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }

    metaImage: file(relativePath: { eq: "switchback-casestudy-meta.jpg" }) {
      publicURL
    }
  }
`

export default CaseStudies

import React from "react"
import BgImg from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"

const HeroWebsiteStyled = styled(BgImg)`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 15rem 0;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
`

const getData = graphql`
  {
    websiteDevelopment: file(relativePath: { eq: "hero-website.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const HeroWebsite = props => {
  const data = useStaticQuery(getData)
  const imageData = data.websiteDevelopment.childImageSharp.fluid
  return (
    <HeroWebsiteStyled Tag="div" fluid={imageData}>
      {props.children}
    </HeroWebsiteStyled>
  )
}

export default HeroWebsite
